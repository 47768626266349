import { useState } from "react";
import axios from "axios";
import countriesList from "../../data/countries.json";
import ReactGA from "react-ga4";

const initialState = {
  first_name: "",
  email: "",
  message: "",
};
export const Contact = ({ role }) => {
  console.log("🚀 ~ Contact ~ role:", role);
  const [contactInfo, setContactInfo] = useState(initialState);
  const [showMessage, setshowMessage] = useState({ type: null, message: null, hide: true, submitButtonMsg: null });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setContactInfo((prevState) => ({ ...prevState, [name]: checked, role: role }));
    } else {
      setContactInfo((prevState) => ({ ...prevState, [name]: value, role: role }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "User",
      action: "Sent a message through the contact form",
    });

    send_notification(contactInfo);

    call_backend({
      ...contactInfo,
      role: role,
    })
      .then((res) => {
        if (res.status === 200) {
          setshowMessage({
            type: "alert alert-success",
            message: `Thank you for contacting us! Your message ID is: '${res.data.contact_id}'`,
            hide: false,
            submitButtonMsg: "Message Sent!",
          });
        } else {
          setshowMessage({
            type: "alert alert-danger",
            message: "Something went wrong. Please try again later...",
            hide: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listOfCountries = () => {
    let list = [];

    countriesList.map((v) => {
      list.push(
        <option
          style={{ color: "lightgray" }}
          value={v.name}
          disabled={v.code === "default" ? true : false}
          selected={v.code === "default" ? true : false}
        >
          {v.name}
        </option>
      );
      return null;
    });

    return list;
  };

  return (
    <>
      <hr style={{ backgroundColor: "lightgrey" }} />
      <div
        style={{
          padding: "0px !important",
        }}
      >
        <div id="ApplyForRole" style={{ color: "black", background: "none" }}>
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <h1
                  style={{
                    fontWeight: "bold",
                    background: "linear-gradient(to right, purple, blue)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {role ? "Apply for the " : "Join Circuland "}
                  <span style={{ textDecoration: "underline" }}>{role}</span>
                  {role && " Role"}
                </h1>
                <br />
                <form name="sentMessage" style={{ padding: "0px !important" }} validate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group required">
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="form-control contactUsControl"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="form-control contactUsControl"
                          placeholder="Surname"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          className="form-select form-control contactUsControl"
                          aria-label="Default select example"
                          id="country"
                          key={"country-selection"}
                          name="country"
                          placeholder="Country"
                          required
                          onChange={handleChange}
                          style={{
                            color: "gray",
                          }}
                        >
                          {listOfCountries()}
                        </select>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        {/* List of sources where they heard of us */}
                        <select
                          className="form-select form-control contactUsControl"
                          aria-label="Default select example"
                          id="source"
                          name="source"
                          required
                          onChange={handleChange}
                          style={{
                            color: "gray",
                          }}
                        >
                          <option selected disabled>
                            How did you hear about us?
                          </option>
                          <option value="Google">Google</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Social Media">Social Media</option>
                          <option value="Webinar">Webinar</option>
                          <option value="Event">Event</option>
                          <option value="Referral">Referral</option>
                          <option value="Other">Other</option>
                        </select>
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control contactUsControl"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="url"
                          id="cvLink"
                          name="cvLink"
                          className="form-control contactUsControl"
                          placeholder="Link to your CV (Google Drive, OneDrive, etc.)"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    {role && role.toLowerCase().includes("developer") && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="url"
                            id="githubLink"
                            name="githubLink"
                            className="form-control contactUsControl"
                            placeholder="Link to your GitHub profile"
                            required
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control contactUsControl"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                      style={{ resize: "vertical" }}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <br />
                  <div class="form-check">
                    <input
                      id="readTermsAndConditions"
                      name="readTermsAndConditions"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      onChange={handleChange}
                      style={{ marginLeft: "10px" }}
                      required
                    />
                    <label class="form-check-label ptext" for="readTermsAndConditions">
                      I have read and I agree with the terms and conditions
                    </label>
                  </div>
                  <br />

                  <div id="success"></div>
                  <br />
                  <div
                    className={showMessage.type}
                    hidden={showMessage.hide}
                    style={{ margin: "0px !important", marginTop: "30px" }}
                    role="alert"
                  >
                    {showMessage.message}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      width: "100%",
                    }}
                  >
                    <button
                      type="submit"
                      className="home-circuland-button"
                      style={{
                        marginTop: "50px",
                        size: "16pt",
                        background: showMessage.submitButtonMsg === "Message Sent" ? "radial-gradient(50, #90EE90, #008000)" : "",
                      }}
                      disabled={showMessage.type === "alert alert-success"}
                    >
                      {showMessage.submitButtonMsg ? showMessage.submitButtonMsg : "Apply"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const call_backend = async (payload) => {
  console.log("🚀 ~ constcall_backend= ~ payload:", payload);
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_CIRCULAND_WEBSITE_CONTACTUS_API}`,
    headers: {
      "x-api-key": `${process.env.REACT_APP_CIRCULAND_WEBSITE_API_KEY}`,
    },
    data: {
      ...payload,
    },
  };

  return axios(config)
    .then(function (response) {
      ReactGA.event({
        category: "User",
        action: "Sent message",
      });
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

const send_notification = async (payload) => {
  const config = {
    method: "POST",
    url: `${process.env.REACT_APP_AZURE_API}`,
    headers: {
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_AZURE_API_KEY}`,
    },
    data: {
      ...payload,
    },
  };

  return axios(config);
};
