import ReactGA from "react-ga4";

export const Home = (props) => {
  const hangleClickForGA = (e) => {
    // e.preventDefault();
    ReactGA.event({
      category: "User",
      action: `Clicked on home - ${e.target.innerHTML}`,
    });
  };

  return (
    // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
    <div id="home" className="section-container section-container-HOME">
      {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
      <div className="page">
        {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
        <div id="homeunderlay" className="page-underlay">
          <div className="grid-Text-Image">
            <div className="paragraph-text">
              <h1 className="welcome-h1">WELCOME TO CIRCULAND</h1>
              <h5 className="subheading-h2" style={{ marginBottom: "35px", marginTop: "20px" }}>
                ENABLING MATERIALS re-use
              </h5>
              <p className="ptext" style={{ color: "black", marginBottom: "20px", fontSize: "16px" }}>
                We provide a <b>one-stop shop</b> for <b>Digital Materials Passports </b> in the Built Environment, unlocking the
                hidden potential of your materials and projects. Our platform simplifies and streamlines the process to enable{" "}
                <b>reuse</b>, automate <b> reporting </b>, and support <b> building safety </b>and <b>facility management</b>. Our
                Key Functions Include:
              </p>
              <ul style={{ listStyle: "decimal", paddingLeft: "30px" }}>
                <li style={{ marginBottom: "10px" }}>Create Digital Products Passports</li>
                <li style={{ marginBottom: "10px" }}>Create Materials Passports for new and existing Developments</li>
                <li style={{ marginBottom: "10px" }}>Easily access robust data for the Materials Stock in the UK</li>
                <li style={{ marginBottom: "45px" }}>Connect supply and demand for re-used materials</li>
              </ul>
              <div className="mobile-center-view">
                <a
                  href="#contact"
                  className="page-scroll"
                  onClick={(event) => {
                    hangleClickForGA(event);
                  }}
                >
                  <button className="home-circuland-button">Get in touch to find out more</button>
                </a>
              </div>
            </div>
            <div className="Image">
              <img src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/1.webp" className="img-responsive" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
// <div className="section-container">
//     {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
//     <div className="page">
//         page
//         {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
//         <div className="page-underlay">
//             page-underlay
//         </div>
//     </div>
// </div>
