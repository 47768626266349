import { useLayoutEffect, useState, useEffect } from "react";

export const About = (props) => {

    const lifecycleMatPasSubtitle = "Recording information throughout \n Materials' Life-Cycle"

    const list = [
        "Properties",
        "Circularity information",
        "Carbon information",
        "Certificates",
        "Cost",
        "Toxicity",
        "re-use Potential",
        "Design for Disassembly",
        "Location",
        "Classification"
    ]

    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);   

    return (
        // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
        <div 
        id="about" 
        className="section-container"
        >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page gradient-background-page-Image">
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay">
                    <div className='grid-Text-Image' style={{ columnGap: '50px', alignItems: 'start' }}>
                        <div className='Image'>
                            <img
                                src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/2.webp"
                                className="img-responsive"
                                alt=""
                                style={{ padding: '0px 0px 0px 0px', margin: '0px', background: 'none', boxShadow: 'none', width: '100%' }}
                            />
                        </div>
                        <div className='Text'>
                            <h1 className='headertext' style={{ margin: '0px', }}>What is a Material Passport</h1>
                            <p className="whatismatpass-text ptext" style={{ marginBottom: '50px' }}>
                                In Circuland we have approached Materials Passports as digital documents, which certify materials’ identity and properties, entitling them to be re-used. The Circuland Materials Passports provide you with the information and confidence you require to re-use construction materials. They include information about materials' properties, location, circularity and carbon performance, material health and sourcing etc. The information is documented through the products’ Certificates and Datasheets. Circuland requires the Materials Passports’ verification prior to their publication in the platform. </p>
                            <div className='about-list-grid'>
                                {list.map((item, index) => {

                                    return (
                                        <div key={index} style={{ marginBottom: '10px' }}>
                                            <div className="inner-whatismatpass-container">
                                                {/* <div className="whatismatpass-icon"> */}
                                                <img className="actorsCardIcon" style={{ width: '30px', height: '30px', marginTop: '0px', borderRadius: '5px' }} src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/2.${index + 2}.webp`} alt={'index'} />
                                                {/* </div> */}
                                                <div>
                                                    {item}
                                                </div>
                                            </div>
                                        </div>)

                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page">
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay page-underlay-LifeCycleMaterialPassport">
                    <div className="lifecycleMatPasheaderContainer">
                        <h1 className='headertext'>Life Cycle Material Passport</h1>
                    </div>
                    <div className='grid-Text-Image-LARGEIMAGE'>
                        <div className='Text' style={{ maxWidth: '100%' }}>
                            <h5 className='lifecycleMatPasSubtitle'>{lifecycleMatPasSubtitle}</h5>

                            <ul style={{ listStyle: 'decimal', paddingLeft: '10px' }}>
                                <li className="lifecyclematpassListItem"><b>Manufacturers:</b> Store information in the Product’s Material Passport about the Product’s properties, material’s health and sourcing, circularity and carbon performance, including the product’s Certificates and Specifications Datasheets.</li>
                                <li className="lifecyclematpassListItem"><b>Contractors:</b> Add information in the Product’s Material Passport about the material’s design (size, dimensions, design for disassembly, etc.) and on-site construction.</li>
                                <li className="lifecyclematpassListItem"><b>Operator/ Facility Manager:</b> Documentation in the Product’s Material Passport information about the Product’s repair and maintenance, throughout its whole life cycle (use, re-use 1, re-use 2, etc.)</li>
                                <li className="lifecyclematpassListItem"><b>Demolition Contractor:</b> Documentation of the product’s recovery at the end of life (upcycling, downcycling, disposal, etc.)</li>
                            </ul>
                        </div>
                        <div className='Image lifecyclematpassImageContainer' >
                            <img
                                src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/3.webp"
                                className="img-responsive lcmp-image"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
// <div className="section-container">
//     {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
//     <div className="page">
//         page
//         {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
//         <div className="page-underlay">
//             page-underlay
//         </div>
//     </div>
// </div>