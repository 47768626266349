import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const generelUtilsSlice = createSlice({
  name: 'generelUtils',
  initialState,
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { set } = generelUtilsSlice.actions

export default generelUtilsSlice.reducer