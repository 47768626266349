// import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Home } from "./pages/home";
import { About } from "./pages/about";
import { CirculandPlatform } from "./pages/circulandPlatform";
import { HowItWorks } from "./pages/howItWorks";
import { Contact } from "./pages/contact";
// import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./website-style.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 10,
  speedAsDuration: false,
});

const App = () => {
  return (
    <>
      <div className="website-container">
        <Navigation />
        <Home />
        <About />
        <CirculandPlatform />
        <HowItWorks />
        {/* <OurTeam /> */}
        <Contact />
      </div>
    </>
  );
};

export default App;
