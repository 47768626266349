import { Contact } from "./ApplyForRole";

const role_description = {
  id: "senior-dev-role",
  name: "Senior Developer",
  description: "Got those coding skills? We are looking for a Senior Javascript Developer to join our team.",
};

export default function Role() {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif !important",
      }}
    >
      <h1
        style={{
          fontWeight: "bold",
          background: "linear-gradient(to right, purple, blue)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Senior JavaScript Developer
      </h1>
      <p> Remote/Hybrid based in Greece [🇬🇷]</p>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <span>
          <p>
            Are you ready to be at the forefront of the Circularity and Net Zero revolution in the construction industry? We are
            Circuland, a VC and Angel Syndicate backed Startup on a mission to transform the Construction Industry to a fairer and
            more circular one. We are looking for a Senior Developer who is not just about hitting targets but is passionate about
            making a real impact.
          </p>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            About Us
          </h3>
          <p>
            We have a SaaS platform revolutionizing the construction industry with Digital Passports driving Circularity, Net Zero
            practices, Safety and transparency. Our mission is to create innovative, sustainable solutions that not only meet
            today’s needs but pave the way for a greener, more efficient future in the Built Environment.
          </p>
          <p>
            As a small, dynamic team, we value creativity, collaboration, and a relentless drive to make an impact. Every team
            member plays a crucial role in our journey, and we are passionate about building a company culture that fosters
            growth, innovation, and shared success.
          </p>
          <p>
            We are currently at an exciting stage of growth, with big ambitions and the backing to make them a reality. If you are
            looking to join a team where your ideas matter and you can truly shape the future, Circuland is the place for you.{" "}
          </p>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What You will Do
          </h3>
          <ul className="dotted-list">
            <li>
              Improving existing features of the product and working on the back-end implementation of new features as we continue
              to scale and strive for innovation.
            </li>
            <li>Ensuring code quality and standards while meeting deadlines and delivering targets set by the product team.</li>
            <li>Working closely with the product team in preparing and approving the scope of work.</li>
            <li>Apply best practices in server security.</li>
            <li>Improve database queries for more throughput and scalability.</li>
            <li>Support other team members through code reviews and continuous feedback.</li>
            <li>Develop scalable server-side applications using Node.js.</li>
            <li>Design and implement RESTful APIs and microservices.</li>
            <li>Optimize application performance and resolve complex issues.</li>
            <li>Collaborate with cross-functional teams to deliver high-quality solutions.</li>
            <li>Mentor junior developers and contribute to our technical strategy.</li>
            <li>Write and maintain documentation (OpenAPI V3, Solution Designs etc).</li>
          </ul>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Who you are
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>Extensive Experience:</strong> You bring +5 years of experience in development roles, specifically in
              backend development. You are proficient with NodeJS, Express, RDBMS and have some frontend experience with ReactJS.
            </li>
            <li>
              <strong>Educational Foundation:</strong> You have a strong academic background in Computer Science, evidenced by a
              Bachelor’s degree and showcased projects on GitHub.
            </li>
            <li>
              <strong>Leadership Skills:</strong> Leading teams and projects come naturally to you. You excel at motivating others
              and driving initiatives forward and want a place where you can grow your career.
            </li>
            <li>
              <strong>Startup Agility:</strong> You're well-versed in the dynamics of start-up environments, adept at adapting
              quickly to changes while maintaining a focus on strategic objectives.
            </li>
            <li>
              <strong>Software Development Expertise:</strong> You have a robust knowledge of Computer Science and Software
              Development, with significant expertise in backend technologies and REST APIs.
            </li>
            <li>
              <strong>Frontend Skills:</strong> While your focus is on backend development, proficiency in frontend development is
              highly desirable.
            </li>
            <li>
              <strong>Object-Oriented Programming:</strong> You are skilled in Object-Oriented Programming, enhancing your ability
              to structure and implement software solutions efficiently.
            </li>
            <li>
              <strong>Independent Work:</strong> You can work independently, especially in remote settings, and possess excellent
              problem-solving, time management and analytical skills.
            </li>
            <li>
              <strong>Fluent in English:</strong> You have an excellent command of English, both written and spoken, enabling you
              to communicate effectively in a professional setting.
            </li>
            <li>
              <strong>Industry Experience:</strong> Experience in the AEC (Architecture, Engineering, and Construction) industry,
              as well as familiarity with AWS and Azure, is highly advantageous but not necessary.
            </li>
          </ul>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What We Offer
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>Competitive Compensation:</strong> We offer a package that reflects your skills and experience, with
              performance-based incentives.
            </li>
            <li>
              <strong>Flexible Work Arrangement:</strong> Work how you work best—whether that is on-site, remote, or a mix of
              both.
            </li>
            <li>
              <strong>Professional Growth:</strong> Opportunities to advance as we scale, including potential for equity
              participation.
            </li>
            <li>
              <strong>Comprehensive Benefits:</strong> We provide a range of benefits designed to support your well-being and
              career growth.
            </li>
          </ul>
          <p style={{ fontWeight: "bold", color: "purple" }}>Ready to Make an Impact?</p>
          <p>
            If you are driven by the desire to create opportunities and bring transformative ideas to life, we’d love to hear from
            you. Join us at Circuland and be part of something bigger than just business — help us shape the future of sustainable
            construction.
          </p>
          <p style={{ fontWeight: "bold", color: "purple" }}>
            <strong>Apply now and let’s build something great together!</strong>
          </p>
        </span>
        <Contact role={"Senior Developer"} />
      </section>
    </div>
  );
}
