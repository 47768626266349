import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Form, Button } from "react-bootstrap";
import { Send, Close } from "@mui/icons-material";
import axios from "axios";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

import "./navigation.css";
export const Navigation = (props) => {
  const [showLaunchedBanner, setShowLaunchedBanner] = useState(true);
  const [notificationStatus, setNotificationStatus] = useState(null);
  const { width, height } = useWindowSize();
  const [runConfetti, setRunConfetti] = useState(false);

  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailIsValid, setEmailIsValid] = useState(false);

  const call_backend = async (payload) => {
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_CIRCULAND_WEBSITE_CONTACTUS_API}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_CIRCULAND_WEBSITE_API_KEY}`,
      },
      data: {
        ...payload,
      },
    };

    return axios(config)
      .then(function (response) {
        ReactGA.event({
          category: "User",
          action: "Sent message",
        });
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  const send_notification = async (payload) => {
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_AZURE_API}`,
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_AZURE_API_KEY}`,
      },
      data: {
        ...payload,
      },
    };

    return axios(config);
  };

  const handleSubmit = (e) => {
    ReactGA.event({
      category: "User",
      action: "User registered interest for Private Beta.",
    });

    setNotificationStatus("Loading");

    send_notification({
      first_name: fullName,
      email: email,
      type: "Prospect Beta User",
    });

    call_backend({
      first_name: fullName,
      email: email,
      type: "Prospect Beta User",
    })
      .then((res) => {
        if (res.status === 200) {
          setNotificationStatus("Success");
        } else {
          setNotificationStatus("Failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hangleClickForGA = (e) => {
    // e.preventDefault();
    ReactGA.event({
      category: "User",
      action: `Clicked on ${e.target.innerHTML}`,
    });
  };

  useEffect(() => {
    const validateEmail = (email) => {
      const re = /^([a-zA-Z0-9._%+-]{3,})@([a-zA-Z0-9.-]{3,})\.[a-zA-Z]{2,}$/;
      return re.test(String(email).toLowerCase());
    };

    setEmailIsValid(validateEmail(email));
  }, [email]);

  const handleMenuItemClick = () => {
    const navbar = document.querySelector("#bs-example-navbar-collapse-1");
    if (navbar.classList.contains("in")) {
      navbar.classList.remove("in");
    }
  };

  const grabEmailOfUser = () => {
    return (
      <div
        className="responsiveFlexContainer"
        // style={{
        //   display: "flex",
        //   gap: "5px",
        // }}
      >
        <Form.Control
          type="name"
          placeholder="Full Name"
          required
          onChange={(e) => {
            setFullName(e.target.value);
          }}
          style={{
            flexGrow: 1,
            width: "200px",
            maxWidth: "300px",
            height: "30px",
            padding: "0 10px",
          }}
        />
        <Form.Control
          type="email"
          placeholder="Email"
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{
            flexGrow: 1,
            minWidth: "250px",
            maxWidth: "300px",
            height: "30px",
            padding: "0 10px",
          }}
        />
        <Button
          variant="primary"
          type="submit"
          style={{
            height: "30px",
            padding: "0 12px",
          }}
          onClick={() => {
            handleSubmit();
          }}
          disabled={!emailIsValid || !fullName || fullName === "" ? true : false}
        >
          <Send />
        </Button>
      </div>
    );
  };

  const notificationSuccess = () => {
    return (
      <span
        style={{
          color: "blue",
        }}
      >
        We'll be in touch soon!
      </span>
    );
  };

  const notificationError = () => {
    return (
      <span
        style={{
          color: "red",
        }}
      >
        An Error has occurred please contact us directly at
        <span
          style={{
            marginLeft: "5px",
          }}
        >
          <a href="mailto:contactus@circuland.co.uk?subject=Beta User Interest&body=I am interested in becoming a beta user, but there was an issue with the notification.">
            contactus@circuland.co.uk
          </a>
        </span>
      </span>
    );
  };

  const renderContent = () => {
    switch (notificationStatus) {
      case "Register":
        return grabEmailOfUser();
      case "Success":
        return notificationSuccess();
      case "Failed":
        return notificationError();
      case "Loading":
        return <span> Sending your request... </span>;
      default:
        return (
          <a
            onClick={() => {
              setNotificationStatus("Register");
            }}
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
          >
            Click here
          </a>
        );
    }
  };

  const launchedBanner = () => {
    return (
      <div
        className="responsiveFlex" // Use the responsive class for flex layout
        style={{
          background: "rgb(255 208 113)", // A softer orange shade
          margin: "20px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          color: "black",
          fontWeight: "bold",
          position: "relative", // Keep for positioning the button
        }}
      >
        🎉 Due to popular demand, we're opening up additional spots in our closed Beta!
        {renderContent()}
        to request to join 🎉
        <Button
          variant="outline-light"
          type="submit"
          onClick={() => {
            setShowLaunchedBanner(false);
          }}
        >
          <Close />
        </Button>
      </div>
    );
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <Confetti
        width={width}
        height={height}
        recycle={false}
        run={runConfetti}
        onConfettiComplete={() => {
          if (runConfetti) {
            setTimeout(() => {
              setRunConfetti(false);
            }, 500);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "white",
          width: "100%",
          background: "linear-gradient(135deg, #1f1c1c, #424242)", // Gradient from black to grey
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "white",
            maxWidth: "1170px",

            padding: "9px",
          }}
          id="join_the_team"
        >
          <span
            className="pulsate"
            onClick={() => {
              setRunConfetti(true);
            }}
          >
            🎉
          </span>
          We are excited to announce we recently closed our pre-seed round. We're now growing...
          <a href="/careers" style={{ paddingLeft: "5px", color: "white", textDecoration: "underline", fontWeight: "bold" }}>
            fancy joining our team?
          </a>
        </div>
      </div>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            style={{
              border: "none", // Remove border
              marginTop: "15px", // Lower the button
              color: "black", // Change color to black
            }}
          >
            <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span>
            <span className="icon-bar"></span> <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#home">
            <div className="logoNavBar">
              <img
                src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/Logo+(colour).png"
                width="70px"
                className="img-responsive"
                alt=""
              />
              {/* <p className="LogoTop">Circuland</p> */}
            </div>
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul
            className="nav navbar-nav navbar-right"
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <li>
              <a
                href="#home"
                className="page-scroll"
                onClick={(event) => {
                  hangleClickForGA(event);
                  handleMenuItemClick();
                }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="page-scroll"
                onClick={(event) => {
                  hangleClickForGA(event);
                  handleMenuItemClick();
                }}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#platform"
                className="page-scroll"
                onClick={(event) => {
                  hangleClickForGA(event);
                  handleMenuItemClick();
                }}
              >
                Platform
              </a>
            </li>
            <li>
              <a
                href="#howItWorks"
                className="page-scroll"
                onClick={(event) => {
                  hangleClickForGA(event);
                  handleMenuItemClick();
                }}
              >
                How It Works
              </a>
            </li>
            {/* <li>
              <a href='#ourTeam' className='page-scroll'>
               Our Team
              </a>
            </li> */}
            <li>
              <a
                href="#contact"
                className="page-scroll"
                onClick={(event) => {
                  hangleClickForGA(event);
                  handleMenuItemClick();
                }}
              >
                Contact
              </a>
            </li>
            <li>
              <a
                style={{
                  background: "linear-gradient(140deg, #0c5085, #9200a97d)" /* Initial gradient background from purple to blue */,
                  color: "white" /* White text */,
                  padding: "10px 20px 10px 20px",
                  border: "none" /* No border */,
                  borderRadius: "5px" /* Slightly rounded corners */,
                  outline: "none" /* Removes the outline */,
                  cursor: "pointer" /* Changes cursor to pointer */,
                  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" /* Subtle shadows for depth */,
                  transition: "all 0.4s ease" /* Smooth transition for all changes */,
                  color: "white",
                }}
                href="https://outlook.office365.com/owa/calendar/MeetingwithCirculand@circuland.co.uk/bookings/"
              >
                Request Demo
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
