import { useState, useLayoutEffect } from "react";
import Carousel from "react-multi-carousel";
import { responsive } from "./carouselConfig"

import { useSelector, useDispatch } from 'react-redux'
import { set } from '../redux/generalUtils'

import ReactGA from "react-ga4";

import "react-multi-carousel/lib/styles.css";

export const CirculandPlatform = () => {
    const dispatch = useDispatch()
    const generalUtils_window = useSelector((state) => state.generelUtils.value)

    const hangleClickForGA = (e) => {
        // e.preventDefault();
        ReactGA.event({
            category: 'User',
            action: `Clicked on home - ${e.target.innerHTML}`
        });
    };

    const benefitList = [
        `Pioneering digital solution and framework  for the creation  of Materials Passports`,
        `Free access to robust material stock data for the UK`,
        `Data robustness through third party verification`,
        `Introduction of guarantee for re-used materials`,
        `Creation of benchmarks and targets for circularity indicators`,
        `Buildings’ circularity analysis through Materials Passports`,
        `Creation of circularity performance reports for products, buildings and portfolio level`,
        `Management of materials’ records throughout their whole life-cycle`
    ]

    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);

            if (generalUtils_window[0] !== window.innerWidth || generalUtils_window[1] !== window.innerHeight) {
                dispatch(set([window.innerWidth, window.innerHeight]))
            }

        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const synergies = {
        one: {
            title: "CIRCULAR \n ECONOMY STATEMENT",
            description: "Through the Materials Passports valuable information can be collected that can directly feed the Post-Construction Circular Economy Statements, such as materials’ Circularity Performance, Material Intensity, Design for Disassembly, Circularity Performance per Building Layer"
        },
        two: {
            title: "WHOLE LIFE \n CARBON ASSESSMENT",
            description: "Automation of the Post-Construction and in-Use Stage Whole Life Carbon Assessment, based on the recorded information in the Materials Passports  (materials specifications, quantities, expected lifespan, carbon factors, etc.)"
        },
        three: {
            title: "ASSET \n MANAGEMENT",
            description: "The Materials Passports allow the management of materials’ Records throughout their whole life-cycle, and the development of statistics that increase maintenance efficiency and return of investment"
        },
        four: {
            title: "MATERIALS \n WASTE MANAGEMENT",
            description: "Collection of information about building materials’ recovery at the end of life (upcycling, downcycling, disposal, etc.), recycling rates per waste stream, allowing for the optimisation of waste management based on actual data"
        }
    }

    const actors = {
        Manufacturers: {
            title: "Manufacturers",
            description: "Manufacturers can document in the Materials Passports their products’ properties and receive Circularity Performance Reports and the Circuland Certificates of verification"
        },
        Contractors: {
            title: "Contractors",
            description: "Contractors can efficiently document in the Materials Passports the information and supporting evidence that is required for undertaking Circularity and Carbon Assessments for the Buildings"
        },
        Developers: {
            title: "Developers",
            description: "Developers can monitor through Materials Passports the Circularity Perforfance of the Developments throughout their life cycle and compare the circularity performance against key targets on a portfolio level"
        },
        FacilitiesManagers: {
            title: "Facilities Managers",
            description: "Facilities Managers can efficiently record information about materials maintenance, repair, and replacement in the Materials Passports and create statistics to optimise maintenance costs"
        },
        LocalCouncils: {
            title: "Local Councils",
            description: "Through the Circuland Materials Stock Database the Local Councils can collect statistics for the Materials Stock on Council Level and create benchmarks and Policy Requirements for Buildings’ Circularity Performance, based on actual robust data"
        },
        PolicyMakers: {
            title: "Policy Makers",
            description: "Through the Circuland Materials Stock Database the Authorities can collect statistics for the Materials Stock on Country Level and for the whole UK, create Policy Requirements in line with the UK Net Zero Commitments and measure the Policies impact"
        },
        EngineeringCompanies: {
            title: "Sustainability Consultants",
            description: "Sustainability Consultants can use the Circuland Platform to offer a greater variety of sustainability services to their clients  (i.e. Materials Passports production, Materials re-use, Circularity and Carbon Assessments, Third Party Verification, etc.)"
        },
        Guarantors: {
            title: "Guarantors",
            description: "The production of Guarantees for re-used materials will be dramatically increased in the following years and the Circuland Platform can play a vital role in developing this field (provision of data, framework, and requirements for producing guarantees) "
        }
    }

    const circulandPlatformCards = {
        Materials_Passports_New_Products: {
            title: 'Materials Passports \n New Products',
            text: "Documentation of the properties, circularity information and certificates of new products by their manufacturers"
        },
        Material_Passports_Buildings: {
            title: "Materials Passports \n Buildings",
            text: "Creation of Materials Passports for Buildings (existing and new) on different levels: whole building, building element categories, instances"
        },
        Materials_Stock_Database: {
            title: "Materials Stock \n Database",
            text: "Presentation of the Materials Stock Data and  Performance against key Circularity Indicators on different levels (UK, Countries, Councils, Developments, Buildings)"
        },
        Marketplace_Re_used_Materials: {
            title: "Marketplace \n Re-used Materials",
            text: "Marketplace to connect supply and demand for re-used materials, using the Materials Passports  for the documentation of the materials available for re-use"
        }
    }

    return (
        // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
        <div id="platform"
            className="section-container"
        >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            < div className="page circuland-platform-gradient-page" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                < div className="page-underlay" >
                    <div className='grid-topBottom'>
                        <div className='TopSection' >
                            <div className="circuland-platform-top-section-header-container" >
                                <h1 className="headertext" style={{ marginBottom: "30px", marginTop: "0px" }}>Circuland Platform</h1>
                                <p className="ptext">The Circuland Platform has been designed to efficiently link Materials Passports for New Products, Materials Passports for Buildings, the UK Materials Stock Database and Marketplace for re-used Materials. Prioritising automation, transparency and data robustness, it provides an innovative tool that simplifies the complex problem of materials re-use. Introducing a clear methodology, third party verification and data validation,  the platform opens a new way to approach and document construction materials.</p>

                                <div className="mobile-center-view" style={{ margin: '50px 0px 30px 0px' }}>
                                    <a href='#contact' onClick={(event) => { hangleClickForGA(event) }}>
                                        <button className='home-circuland-button'>Get in touch to find out more</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='BottomSection'>

                            {size[0] < 1025 ?
                                <Carousel
                                    infinite={true}
                                    autoPlay={true}
                                    containerClass='carousel-container'
                                    itemClass='carousel-image-item'
                                    autoPlaySpeed={60000}
                                    responsive={responsive}>
                                    {Object.keys(circulandPlatformCards).map((key, index) => {
                                        return (
                                            <div className="smallCard-icon-text-100pc-container">
                                                <div className="smallCard-icon-text">
                                                    <div className="icon">
                                                        <img alt={key} className="card-icon-image" src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/4.${index + 1}.webp`} />
                                                    </div>
                                                    <div className="title">
                                                        <h3 className="smallCard-title-header display-linebreak">{circulandPlatformCards[key].title}</h3>
                                                    </div>
                                                    <div className="text">
                                                        <p className="smallCard-text-description">{circulandPlatformCards[key].text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Carousel>
                                :
                                <div className='smallCardHolder-grid'>
                                    {Object.keys(circulandPlatformCards).map((key, index) => {
                                        return (
                                            <div className="smallCard-icon-text">
                                                <div className="icon">
                                                    <img alt={key} className="card-icon-image" src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/4.${index + 1}.webp`} />
                                                </div>
                                                <div className="title">
                                                    <h3 className="smallCard-title-header display-linebreak">{circulandPlatformCards[key].title}</h3>
                                                </div>
                                                <div className="text">
                                                    <p className="smallCard-text-description">{circulandPlatformCards[key].text}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                        </div>
                    </div>
                </div >
            </div >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            < div className="page" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                < div className="page-underlay DEFAULT-MARGIN-BOTTOM-100PX" >
                    <p style={{ margin: '0px 0px 40px 0px' }}> The Circuland Platform offers a circle of linked functions to increase efficiency and transparency: </p>
                    <img
                        src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/5.webp"
                        style={{ "maxWidth": "95%" }}
                        alt="Circuland Platform"
                    />
                </div >
            </div >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            < div className="page gradient-background-page-Image-theFacts" style={{background: 'none'}} >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                < div className="page-underlay DEFAULT-MARGIN-TOP-100PX" >
                    <div className="infographic-container" style={{ columnGap: '50px', color: '#666666' }}>
                        <div>
                            <h1 className="headertext" style={{ marginBottom: "30px", marginTop: "0px" }}>Action is required now</h1>
                            <ul style={{ listStyle: 'disc', paddingLeft: '10px', color: '#666666' }}>
                                <li className="infographic-listItem">The Construction Industry is responsible for approximately 60% of total UK materials</li>
                                <li className="infographic-listItem">The waste from the Construction Industry accounts for approximately one third of the total UK waste generation</li>
                                <li className="infographic-listItem">In total, the Built Environment is thought to be responsible for around 40 % of global CO2 emissions</li>
                                <li className="infographic-listItem">There is catalysing market demand for net zero buildings as organisations commit to net zero carbon by 2030 for the building areas under their direct control  </li>
                                <li className="infographic-listItem">The Greater London Authority have introduced the target of 20% re-used or recycled content of the materials of the whole building by value, for all major developments, and the developments referable to the Mayor</li>
                            </ul>
                        </div>
                        <img src='https://circuland-website-images.s3.eu-west-2.amazonaws.com/6.webp'
                            alt="DEFRA Chart"
                            style={{ "maxWidth": "90%" }}
                        />
                    </div>
                </div >
            </div >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            < div className="page" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                < div className="page-underlay" >
                    <h1 className="headertext" style={{ margin: '0px 0px 30px 0px', color: 'rgb(102, 102, 102)' }}> Circuland Platform Benefits </h1>
                    <div className='CirculandPlatformBenefits-grid'>
                        {benefitList.map((benefit, index) => {
                            return (
                                <div className="benefitCard" key={index}>

                                    <div className="benefitCardIconContainer"> <img className="benefitCardIcon" src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/7.${index + 1}.webp`} alt={benefit} /> </div>
                                    <div className="benefitCardDescription">{benefit}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="CirculandPlatformBenefits-bottomcontainer">

                        {/* <p className="ptext" style={{ color: '#027585' }}>
                            <span className="speechmark">&#8220;</span>
                            Our target is to work together, to combine our vision, insights and points of view to make reality the circular industry we have dreamed of.
                            <span className="speechmark">&#8221;</span>
                        </p> */}
                    </div>
                </div >
            </div >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            < div className="page gradient-background-page-Image-synergies"
            >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                < div className="page-underlay"

                >
                    <h1 id="MaterialsPassportsSynergies" className="headertext"
                        style={{ color: 'white', marginBottom: '50px' }}
                    >Materials Passports Synergies</h1>

                    {size[0] < 1025 ?
                        <Carousel
                            infinite={true}
                            autoPlay={true}
                            containerClass='carousel-container'
                            itemClass='carousel-image-item'
                            autoPlaySpeed={60000}
                            responsive={responsive}>
                            {Object.keys(synergies).map((key, index) => {
                                return (
                                    <div style={{ height: '100%' }}>
                                        <div className="smallCard-icon-text-100pc-container" style={{ paddingTop: '90px' }}>
                                            <div className="smallCard-icon-text" style={{ borderRadius: '22px' }}>
                                                <div className="circleHeaderDiv">
                                                    <div className="circleHeaderDivInner">
                                                        <div className="icon">
                                                            <img alt={key} className="card-icon-image" style={{  padding: '0px' }} src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/8.${index + 1}.webp`} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="title">
                                                    <h3
                                                        style={{ margin: '-12px 0px 20px 0px' }}
                                                        className="smallCard-title-header-SynergiesCustom display-linebreak">
                                                        {synergies[key].title}</h3>
                                                </div>
                                                <div className="ptext smallLineHeight">
                                                    <p className="smallCard-text-description display-linebreak"
                                                        style={{ minHeight: '200px' }}
                                                    > {synergies[key].description} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                        :
                        <div className='smallCardHolder-grid'
                            style={{ marginTop: '40px' }}
                        >
                            {Object.keys(synergies).map((key, index) => {
                                return (
                                    <div className="smallCard-icon-text" style={{ borderRadius: '22px' }}>
                                        <div className="circleHeaderDiv">
                                            <div className="circleHeaderDivInner">
                                                <div className="icon">
                                                    <img alt={key} className="card-icon-image" style={{ padding: '0px' }} src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/8.${index + 1}.webp`} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title">
                                            <h3
                                                style={{ margin: '-12px 0px 20px 0px' }}
                                                className="smallCard-title-header-SynergiesCustom display-linebreak">
                                                {synergies[key].title}</h3>
                                        </div>
                                        <div className="ptext smallLineHeight">
                                            <p className="smallCard-text-description display-linebreak"
                                                style={{ minHeight: '200px' }}
                                            > {synergies[key].description} </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                </div >
            </div >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            < div className="page" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                < div className="page-underlay" >
                    <h1 className="headertext" style={{ margin: '0px 0px 30px 0px', color: 'rgb(102, 102, 102)' }}> Circuland Platform - Who is it for </h1>
                    {size[0] < 900 ?
                        <Carousel
                            infinite={true}
                            autoPlay={true}
                            containerClass='carousel-container'
                            itemClass='carousel-image-item'
                            autoPlaySpeed={60000}
                            responsive={responsive}>
                            {Object.keys(actors).map((key, index) => {
                                return (
                                    <div className="smallCard-icon-text-100pc-container">

                                        <div className="actorCardContainer" key={index}>

                                            <div className="benefitCardIconContainer"> <img className="actorsCardIcon" style={{ width: '35%' }} src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/9.${index + 1}.webp`} alt={index} /> </div>
                                            <div className="actorsCardTitle">{actors[key].title}</div>
                                            <div className="actorsCardDescription">{actors[key].description}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                        :
                        <div className='CirculandPlatformActors-grid'>
                            {Object.keys(actors).map((key, index) => {
                                return (
                                    <div className="actorCardContainer" key={index}>

                                        <div className="benefitCardIconContainer"> <img className="actorsCardIcon" style={{ width: '35%' }} src={`https://circuland-website-images.s3.eu-west-2.amazonaws.com/9.${index + 1}.webp`} alt={index} /> </div>
                                        <div className="actorsCardTitle">{actors[key].title}</div>
                                        <div className="actorsCardDescription">{actors[key].description}</div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div >
            </div >
        </div >
    )
}

// // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
// <div className="section-container">
//     {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
//     <div className="page">
//         page
//         {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
//         <div className="page-underlay">
//             page-underlay
//         </div>
//     </div>
// </div>