// https://dev.to/ramonak/react-enable-google-analytics-after-a-user-grants-consent-5bg3
import ReactGA from "react-ga4";

export const initGA = (id: string) => {
    // console.log(process.env.NODE_ENV)
    // ReactGA.initialize(id, {debug: true});
ReactGA.initialize([
  {
    trackingId: id,
  }])
};