import { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Form, Button } from "react-bootstrap";
import { Send, Close } from "@mui/icons-material";
import axios from "axios";

import "./navigation.css";
export const JoinUsNavigation = (props) => {
  const [showLaunchedBanner, setShowLaunchedBanner] = useState(true);
  const [notificationStatus, setNotificationStatus] = useState(null);

  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailIsValid, setEmailIsValid] = useState(false);

  const call_backend = async (payload) => {
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_CIRCULAND_WEBSITE_CONTACTUS_API}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_CIRCULAND_WEBSITE_API_KEY}`,
      },
      data: {
        ...payload,
      },
    };

    return axios(config)
      .then(function (response) {
        ReactGA.event({
          category: "User",
          action: "Sent message",
        });
        return response;
      })
      .catch(function (error) {
        return error;
      });
  };

  const send_notification = async (payload) => {
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_AZURE_API}`,
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_AZURE_API_KEY}`,
      },
      data: {
        ...payload,
      },
    };

    return axios(config);
  };

  const handleSubmit = (e) => {
    ReactGA.event({
      category: "User",
      action: "User registered interest for Private Beta.",
    });

    setNotificationStatus("Loading");

    send_notification({
      first_name: fullName,
      email: email,
      type: "Prospect Beta User",
    });

    call_backend({
      first_name: fullName,
      email: email,
      type: "Prospect Beta User",
    })
      .then((res) => {
        if (res.status === 200) {
          setNotificationStatus("Success");
        } else {
          setNotificationStatus("Failed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hangleClickForGA = (e) => {
    // e.preventDefault();
    ReactGA.event({
      category: "User",
      action: `Clicked on ${e.target.innerHTML}`,
    });
  };

  useEffect(() => {
    const validateEmail = (email) => {
      const re = /^([a-zA-Z0-9._%+-]{3,})@([a-zA-Z0-9.-]{3,})\.[a-zA-Z]{2,}$/;
      return re.test(String(email).toLowerCase());
    };

    setEmailIsValid(validateEmail(email));
  }, [email]);

  const handleMenuItemClick = () => {
    const navbar = document.querySelector("#bs-example-navbar-collapse-1");
    if (navbar.classList.contains("in")) {
      navbar.classList.remove("in");
    }
  };

  const grabEmailOfUser = () => {
    return (
      <div
        className="responsiveFlexContainer"
        // style={{
        //   display: "flex",
        //   gap: "5px",
        // }}
      >
        <Form.Control
          type="name"
          placeholder="Full Name"
          required
          onChange={(e) => {
            setFullName(e.target.value);
          }}
          style={{
            flexGrow: 1,
            width: "200px",
            maxWidth: "300px",
            height: "30px",
            padding: "0 10px",
          }}
        />
        <Form.Control
          type="email"
          placeholder="Email"
          required
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{
            flexGrow: 1,
            minWidth: "250px",
            maxWidth: "300px",
            height: "30px",
            padding: "0 10px",
          }}
        />
        <Button
          variant="primary"
          type="submit"
          style={{
            height: "30px",
            padding: "0 12px",
          }}
          onClick={() => {
            handleSubmit();
          }}
          disabled={!emailIsValid || !fullName || fullName === "" ? true : false}
        >
          <Send />
        </Button>
      </div>
    );
  };

  const notificationSuccess = () => {
    return (
      <span
        style={{
          color: "blue",
        }}
      >
        We'll be in touch soon!
      </span>
    );
  };

  const notificationError = () => {
    return (
      <span
        style={{
          color: "red",
        }}
      >
        An Error has occurred please contact us directly at
        <span
          style={{
            marginLeft: "5px",
          }}
        >
          <a href="mailto:contactus@circuland.co.uk?subject=Beta User Interest&body=I am interested in becoming a beta user, but there was an issue with the notification.">
            contactus@circuland.co.uk
          </a>
        </span>
      </span>
    );
  };

  const renderContent = () => {
    switch (notificationStatus) {
      case "Register":
        return grabEmailOfUser();
      case "Success":
        return notificationSuccess();
      case "Failed":
        return notificationError();
      case "Loading":
        return <span> Sending your request... </span>;
      default:
        return (
          <a
            onClick={() => {
              setNotificationStatus("Register");
            }}
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
          >
            Click here
          </a>
        );
    }
  };

  const launchedBanner = () => {
    return (
      <div
        className="responsiveFlex" // Use the responsive class for flex layout
        style={{
          background: "rgb(255 208 113)", // A softer orange shade
          margin: "20px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          color: "black",
          fontWeight: "bold",
          position: "relative", // Keep for positioning the button
        }}
      >
        🎉 Due to popular demand, we're opening up additional spots in our closed Beta!
        {renderContent()}
        to request to join 🎉
        <Button
          variant="outline-light"
          type="submit"
          onClick={() => {
            setShowLaunchedBanner(false);
          }}
        >
          <Close />
        </Button>
      </div>
    );
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <div className="logoNavBar">
            <img
              src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/Logo+(colour).png"
              width="70px"
              className="img-responsive"
              alt=""
            />
            <a className="navbar-brand page-scroll" href="/careers">
              <p className="LogoTop">Join us @ Circuland</p>
            </a>

            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span>
              <span className="icon-bar"></span> <span className="icon-bar"></span>
            </button>
          </div>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul
            className="nav navbar-nav navbar-right"
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <li>
              <a
                href="/"
                className="page-scroll"
                onClick={(event) => {
                  hangleClickForGA(event);
                  handleMenuItemClick();
                }}
              >
                Back to the site
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
