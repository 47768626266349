import { Contact } from "./ApplyForRole";

const role_description = {
  id: "senior-bd-manager",
  name: "Senior Business Development Manager",
  description: "Got a passion for business development and want to work in Sustainability? Maybe you should apply for this role!",
};

export default function role() {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif !important",
      }}
    >
      <h1
        style={{
          fontWeight: "bold",
          background: "linear-gradient(to right, purple, blue)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Senior Business Development Manager
      </h1>
      <p> Remote/Hybrid based in London UK [🇬🇧]</p>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <span>
          <p>
            Are you ready to be at the forefront of the Circularity and Net Zero revolution in the construction industry? We are
            Circuland, an ambitious start-up committed to driving data-based Circularity in Construction through Digital
            Passports. We are looking for a Senior Business Development Manager who is not just about hitting targets but is
            passionate about making a real impact.
          </p>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            About Us
          </h3>
          <p>
            At Circuland, we are a pioneering SaaS platform revolutionizing the construction industry with Digital Passports
            driving Circularity, Net Zero practices and transparency. Our mission is to create innovative, sustainable solutions
            that not only meet today’s needs but also pave the way for a greener, more efficient future in construction.
          </p>
          <p>
            As a small, dynamic team, we value creativity, collaboration, and a relentless drive to make an impact. Every team
            member plays a crucial role in our journey, and we are passionate about building a company culture that fosters
            growth, innovation, and shared success.
          </p>
          <p>
            We are currently at an exciting stage of growth, with big ambitions and the backing to make them a reality. If you are
            looking to join a company where your ideas matter and you can truly shape the future, Circuland is the place for you.
          </p>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What you will do
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>Shape Our Future:</strong> Lead the charge in validating and refining our business model and pricing
              strategies. Your insights will be critical as we navigate the challenges of scaling our early-stage start-up.
            </li>
            <li>
              <strong>Drive Growth:</strong> Identify and seize new business opportunities. Develop go-to-market strategies that
              resonate with our audience and position Circuland as a leader in Circularity & Digital Passports in Construction.
            </li>
            <li>
              <strong>Build Relationships:</strong> Forge and nurture strategic partnerships that propel us forward. You will also
              be responsible for acquiring new clients and managing ongoing client relationships to ensure satisfaction and
              long-term collaboration.
            </li>
            <li>
              <strong>Innovate:</strong> Keep us ahead of the curve by tracking market trends, and ensuring we are always aligned
              with the latest legislation and industry standards.
            </li>
          </ul>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Who you are
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>A Strategic Innovator:</strong> You bring 5+ years of experience in business development, ideally within the
              construction industry. You are not just strategic—you are innovative, always looking for ways to push the
              boundaries.
            </li>
            <li>
              <strong>A Networker Extraordinaire:</strong> Your connections within the industry are unmatched, and you know how to
              leverage them to build lasting partnerships and secure new clients.
            </li>
            <li>
              <strong>A Results-Driven Achiever:</strong> You have a proven track record of driving revenue growth and making
              things happen. You set ambitious goals and don’t stop until you exceed them.
            </li>
            <li>
              <strong>A Start-Up Enthusiast:</strong> You thrive in dynamic environments and are excited about the challenges and
              opportunities of working in a fast-paced start-up.
            </li>
          </ul>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What We Offer
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>Competitive Compensation:</strong> We offer a package that reflects your skills and experience, with
              performance-based incentives.
            </li>
            <li>
              <strong>Flexible Work Arrangement:</strong> Work how you work best—whether that is on-site, remote, or a mix of
              both.
            </li>
            <li>
              <strong>Professional Growth:</strong> Opportunities to advance as we scale, including potential for equity
              participation.
            </li>
            <li>
              <strong>Comprehensive Benefits:</strong> We provide a range of benefits designed to support your well-being and
              career growth.
            </li>
          </ul>
          <p style={{ fontWeight: "bold", color: "purple" }}>Ready to Make an Impact?</p>
          <p>
            If you are driven by the desire to create opportunities and bring transformative ideas to life, we’d love to hear from
            you. Join us at Circuland and be part of something bigger than just business — help us shape the future of sustainable
            construction.
          </p>
          <p style={{ fontWeight: "bold", color: "purple" }}>
            <strong>Apply now and let’s build something great together!</strong>
          </p>
        </span>
        <Contact role={"Business Development Manager"} />
      </section>
    </div>
  );
}
