import { Contact } from "./ApplyForRole";

const role_description = {
  id: "business-ops-specialist",
  name: "Product and Business Operations Specialist",
  description: "Are you a business operations specialist? We are looking for you!",
};

export default function role() {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1
        style={{
          fontWeight: "bold",
          background: "linear-gradient(to right, purple, blue)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        Product and Business Operations Specialist
      </h1>
      <p> Remote/Hybrid based in Greece [🇬🇷]</p>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <span>
          <p>
            Are you ready to play a crucial role in driving innovation and operational excellence within a dynamic start-up
            environment? At Circuland, we’re on a mission to revolutionize the construction industry through Digital Passports,
            driving Circularity, Net Zero practices, and transparency. We are looking for a Product and Business Operations
            Specialist who is passionate about supporting product development and business operations to make a real impact.
          </p>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            About Us
          </h3>
          <p>
            At Circuland, we are a pioneering startup with a SaaS platform revolutionizing the construction industry with Digital
            Passports driving Circularity, Net Zero practices, Safety and transparency. Our mission is to create innovative,
            sustainable solutions that not only meet today’s needs but pave the way for a greener, more efficient future in the
            Built Environment.
          </p>
          <p>
            As a small, dynamic team, we value creativity, collaboration, and a relentless drive to make an impact. Every team
            member plays a crucial role in our journey, and we are passionate about building a company culture that fosters
            growth, innovation, and shared success.
          </p>
          <p>
            We are currently at an exciting stage of growth, with big ambitions and the backing to make them a reality. If you are
            looking to join a company where your ideas matter and you can truly shape the future, Circuland is the place for you.
          </p>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What You Will Do
          </h3>
          <ul className="dotted-list">
            <li>
              <strong> Support Product Development: </strong> Collaborate closely with the CEO and product team to gather and
              synthesise critical business-related information that will inform and shape our product development. Your role will
              be vital in ensuring that our products meet market needs and align with industry standards, policies and our
              strategic goals.
            </li>
            <li>
              <strong>Business Operations & Customer Support:</strong> Engage in both client-facing and internal operational
              activities, focusing on streamlining processes and enhancing customer satisfaction. You will play a key role in
              maintaining the smooth operation of our business functions.
            </li>
            <li>
              <strong> Assist in Marketing & Business Development: </strong> Contribute to the creation and execution of marketing
              strategies, while also supporting key business development initiatives. Your work will help drive brand awareness
              and expand our market presence.
            </li>
          </ul>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Who You Are
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>Analytical & Organised:</strong> You excel at managing data, structuring tasks, and supporting operational
              efficiency.
            </li>
            <li>
              <strong>Customer-Oriented:</strong> You have strong customer support skills and are committed to ensuring a high
              level of client satisfaction.
            </li>
            <li>
              <strong>Curious & Open-Minded:</strong> Naturally curious, you seek to understand and improve processes, and are
              adaptable to change.
            </li>
            <li>
              <strong>Collaborative:</strong> You work well with others, particularly in supporting senior management across
              multiple functions.
            </li>
            <li>
              <strong>Driven by Impact:</strong> You are passionate about making a meaningful impact in a fast-paced start-up
              environment.
            </li>
            <li>
              <strong>Fluent in English:</strong> You have an excellent command of English, both written and spoken, enabling you
              to communicate effectively in a professional setting.
            </li>
          </ul>
          <h3
            style={{
              fontWeight: "bold",
              background: "linear-gradient(to right, purple, blue)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What We Offer
          </h3>
          <ul className="dotted-list">
            <li>
              <strong>Competitive Compensation:</strong> We offer a package that reflects your skills and experience, with
              performance-based incentives.
            </li>
            <li>
              <strong>Flexible Work Arrangement:</strong> Work how you work best—whether that is on-site, remote, or a mix of
              both.
            </li>
            <li>
              <strong>Professional Growth:</strong> Opportunities to advance as we scale, including potential for equity
              participation.
            </li>
            <li>
              <strong>Comprehensive Benefits:</strong> We provide a range of benefits designed to support your well-being and
              career growth.
            </li>
          </ul>
          <p style={{ fontWeight: "bold", color: "purple" }}>Ready to Make an Impact?</p>
          <p>
            If you are driven by the desire to create opportunities and bring transformative ideas to life, we’d love to hear from
            you. Join us at Circuland and be part of something bigger than just business — help us shape the future of sustainable
            construction.
          </p>
          <p style={{ fontWeight: "bold", color: "purple" }}>
            <strong>Apply now and let’s build something great together!</strong>
          </p>
        </span>
        <Contact role={"Product and Business Operations Specialist"} />
      </section>
    </div>
  );
}
