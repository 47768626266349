import Carousel from "react-multi-carousel";
import { responsive, single } from "./carouselConfig"
import { FiExternalLink } from 'react-icons/fi';

import ReactGA from "react-ga4";
import { useSelector } from 'react-redux'

export const HowItWorks = (props) => {
    const generalUtils_window = useSelector((state) => state.generelUtils.value)

    const hangleClickForGA = (e) => {
        // e.preventDefault();
        ReactGA.event({
            category: 'User',
            action: 'Clicked on Waterman link'
        });
    };

    const creationOfMatPass = {
        SupportingEvidence: {
            title: "Supporting Evidence:",
            description: "We highly value transparency, therefore documentation through supporting evidence is critical in the creation of Materials Passports in Circuland. Certificates, Datasheets, Confirmation Letters etc. need to accompany Materials Passports, confirming the information that is recorded in them and they can provide additional information that is not captured within the Materials Passports."
        },
        Ownership: {
            title: "Ownership:",
            description: "Materials Passports need to stay up to date. Thus, it is crucial that we not only create Materials Passports but that we ensure their proper maintenance. This is the reason why ownership should be identified for all the Materials Passports that are created in Circuland. The Materials Passports owners are responsible for updating the information about materials throughout their life-cycle. The ownership can be transferred to different parties throughout the life-cycle of the materials."
        },
        ThirdPartyVerification: {
            title: "Third Party Verification:",
            description: "Data validity and robustness is at the core of the Circuland Materials Passports. Third Party verification is required before data is published in the Circuland Materials Stock Database. Through the Third Party verification, it is verified that the information that is included in the Materials Passports is valid, based on supporting evidence.  Third party verification is provided by Circuland and independent parties with the appropriate qualifications."
        },
    }

    return (
        // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
        <div id="howItWorks"
            className="section-container"
        >
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page gradient-background-page-HowItWorks DEFAULT-PAGE-HOW_IT_WORKS_PAGE">
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay DEFAULT-UNDERLAY-HOW_IT_WORKS_PAGE">
                    <div className='howitworks-dual-grid'>
                        <div className='left-grid'>
                            <h1 className="headertext"
                                style={{
                                    marginBottom: "15px"
                                }}
                            > How it works </h1>
                            <h4
                                style={{
                                    marginBottom: "25px",
                                    color: 'rgba(255, 153, 0, 1)',
                                    fontSize: '24px'
                                }}
                            > In collaboration with Waterman Group </h4>
                            <p className="ptext"
                                style={{
                                    marginBottom: "25px",
                                }}
                            >
                                Waterman Group is a multidisciplinary consultancy providing sustainable solutions to meet the planning, engineering design and project delivery needs of the property, infrastructure, environment and energy markets.
                            </p>
                            <p className="ptext"
                                style={{
                                    marginBottom: "25px",
                                }}
                            >
                                Waterman Group provides technical advice to Circuland on the methodology for creating Materials Passports for Buildings. Waterman is developing a protocol to standardise the process of producing and reporting Materials Passports across the UK. The Circuland Platform is based on the key principles that have been developed by Waterman and will be updated in line with the Waterman Materials Passports Protocol.
                            </p>
                            <p className="ptext">
                                For more information
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.watermangroup.com/watermanmaterialspassportsframework/"
                                    style={{
                                        color: 'rgba(255, 153, 0, 1)',
                                        marginLeft: '5px'
                                    }}
                                    onClick={(event) => { hangleClickForGA(event) }}
                                >click here
                                    <FiExternalLink style={{ marginLeft: '5px' }} />
                                </a>
                            </p>
                        </div>
                        <div className='right-grid'>
                            <img
                                src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/10.webp"
                                style={{ width: '80%' }}
                                alt="" />
                        </div>

                    </div>
                </div>
            </div>
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page">
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay DEFAULT-WIDTH-70PC DEFAULT-UNDERLAY-CREATION-OF-MAT-PASS">
                    <div className="creationOfMatPassHeaderContainer">
                        <h1 className="headertext" style={{ margin: '0px 0px 20px 0px', color: '#027585' }}> Creation of Materials Passports </h1>
                        <p className="ptext"> The creation of Materials Passports in the Circuland Platform is based on three key principles:  </p>
                    </div>
                    {Object.keys(creationOfMatPass).map((key, index) => {
                        const icon = `https://circuland-website-images.s3.eu-west-2.amazonaws.com/11.${index + 1}.webp`
                        return (
                            <div className="rowGridContainer mobile-rowGridContainer" >
                                <div className='rowIconContainer-left'>
                                    <div className='rowIconContainer'>
                                        <img src={icon}
                                            alt='icon-1'
                                            style={{ width: '65px', height: 'auto' }}
                                        />
                                    </div>
                                </div>
                                <div className='rowIconContainer-right'>
                                    <div
                                        className="creationOfMatPassDescription"
                                        style={{
                                            marginBottom: '8px',
                                            fontSize: '16px'
                                        }}
                                    >
                                        <b
                                            style={{
                                                color: '#027585'
                                            }}
                                        >{creationOfMatPass[key].title}</b>
                                    </div>
                                    <p className="creationOfMatPassDescription" style={{ maxWidth: '81%', fontSize: '16px' }}>
                                        {creationOfMatPass[key].description}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page gradient-background-page-NewPassMat2 DEFAULT-PAGE-MAT-PASS-FOR-NEW-PRODS" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay">
                    <div className='howitworks-dual-grid-newProducts'>
                        <div className='left-grid-newProducts'>
                            <h1 className='headertext howitworksheaders'> Materials Passports for new products </h1>
                            <p className='ptext mediumLineHeight'
                                style={{
                                    fontSize: '16px'
                                }}
                            >
                                The Materials Passports for new products are created by their Manufacturers. The Manufacturers import into the Circuland Platform the required information that needs to be documented in the Material Passport, including supporting evidence (Certificates, Datasheets, etc.). <br /><br />
                                Then the  Materials Passports' Ownership needs to be identified, ensuring that the Materials Passports remain up to date, in the case that the products’ specifications and supporting evidence needs to be updated in the future.<br /><br />
                                The information in the Materials Passports needs to be verified through a Third Party (Circuland or independent party with appropriate qualifications), to secure the data validity. After the Third Party verification is confirmed, a Circularity Performance Report is automatically created for the products, which accompanies the products’ Materials Passports. Additionally, a Circuland Certificate of Verification is produced and sent to the Manufacturer.<br /><br />
                                After the information is verified, the Materials Passports are publicly available in the Circuland Materials Stock Database, where all users can see them and also compare them with other products.
                            </p>
                            <img
                                src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/12.1.webp"
                                alt="Steps for new material passports"
                                style={{ width: '90%' }}
                            />
                        </div>
                        <div className='right-grid-newProducts'>
                            <h1 className='headertext howitworksheaders'> Materials Passports for Buildings </h1>
                            <p className='ptext mediumLineHeight'
                                style={{
                                    fontSize: '16px'
                                }}
                            >
                                The creation of Materials Passports for Buildings is based on the as-built information. During the Buildings' Construction and Post-Construction, the Contractors and Manufacturers are responsible for providing the required information for the production of Materials Passports for the Buildings. Facilities Managers are responsible for updating the Materials Passports during the operation of the Buildings and Demolition/ Strip-out Contractors should monitor information about the materials' end of life. <br /><br />
                                An Excel template is provided by Circuland, indicating the information that is required to be uploaded in the platform. It is highly recommended that the majority of the information be exported from as-built BIM models.  The completed Excel template is uploaded in the platform, where the information is classified and visualised in the Platform for the production of Materials Passports for Buildings. The input can be further edited in the Platform, where supplementary information can also be added.<br /><br />
                                Following the completion of the required information for the Materials Passports production, the ownership of all Materials Passports needs to be identified (i.e. Buildings' Facility managers). The Materials Passports owners are responsible for updating the Materials Passports throughout the Use Stage of the Building (repair, maintenance. replacement) and Materials end of life.<br /><br />
                                The provided information at each stage needs to be verified through Third Party (i.e. independent parties with appropriate qualifications), as a mechanism for providing quality assurance. Following the verification process, the Buildings' Materials Passports are added in the Circuland Materials Stock Database and a Circularity Performance Report is automatically produced by the platform. Finally, a Circuland Certificate of Verification is sent to the Buildings' Developer.              </p>
                        </div>
                    </div>
                    <img
                        src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/12.webp"
                        alt="Steps for new material passports"
                        style={{ width: '90%' }}
                    />
                </div>
            </div>
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page">
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay DEFAULT-WIDTH-70PC">
                    <div className="matstockdbheadecontainer">
                        <div className='matdatabase-section1'
                            style={{
                                width: '80%'
                            }}
                        >
                            <h1 className="headertext" style={{ margin: '0px 0px 20px 0px', color: '#027585' }}>Circuland Materials Stock Database </h1>
                            <p className="ptext"
                                style={{
                                    color: '#43AAAA',
                                    fontSize: '18px',
                                    lineHeight: '1px',
                                    fontWeight: '700',
                                    marginBottom: '20px'
                                }}
                            >The free-access Circuland Materials Stock Database provides accessible data for the Buildings’ Materials Stock in the UK and indicates the performance of buildings and products against key Circularity Indicators. </p>
                            <p className="ptext"
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '10x'
                                }}
                            >The content of the Circuland Database derives from the Circuland Materials Passports for Buildings and Products that have been created and verified on the platform. The Circuland platform classifies and presents the information about the Buildings' Materials Stock in the UK on different levels. This includes the whole UK, UK countries (England, Northern Ireland, Scotland, Wales), Councils, Developments, Buildings, and New Products.</p>
                        </div>
                        <img
                            src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/13.0.webp"
                            alt="Material Stock DB Illustration"
                            style={{
                                width: '90%',
                                margin: '50px 0px 30px 0px'
                            }}
                        />
                    </div>
                    <br />
                    <div className='matdatabase-section3 ptext'>
                        The Circuland Materials Stock Database aims to:
                        {/* list with bullet points */}
                        <ul
                            style={{ listStyleType: "disc", padding: "20px", lineHeight: '30px', width: '80%' }}
                        >
                            <li>Provide transparency on the materials resources in the built environment in the UK;</li>
                            <li>Create benchmarks for material intensity and buildings’ circularity which can shape the basis for minimum acceptable standards in the industry;</li>
                            <li>Validate and track progress on materials re-use in the built environment.</li>
                        </ul>
                    </div>

                    <br />

                    {generalUtils_window[0] < 500 ?
                        <Carousel
                            infinite={true}
                            autoPlay={true}
                            containerClass='carousel-container'
                            itemClass='carousel-image-item'
                            autoPlaySpeed={60000}
                            responsive={single}
                        >
                            <div className="smallCard-icon-text-100pc-container">
                                <div className='matdatabase-section4-left'>
                                    <img
                                        src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/13.1.webp"
                                        alt="Material Stock DB Illustration"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className="smallCard-icon-text-100pc-container">
                                <div className='matdatabase-section4-right'>
                                    <img
                                        src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/13.2.webp"
                                        alt="Material Stock DB Illustration"
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </Carousel>
                        :

                        <div className='matdatabase-section4-grid'>
                            <div className='matdatabase-section4-left'>
                                <img
                                    src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/13.1.webp"
                                    alt="Material Stock DB Illustration"
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className='matdatabase-section4-right'>
                                <img
                                    src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/13.2.webp"
                                    alt="Material Stock DB Illustration"
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
            <div className="page" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay-noTop DEFAULT-WIDTH-70PC"
                    style={{
                        margin: '0px 0px 80px 0px !important',
                        topPadding: '0px !important'
                    }}
                >
                    <div className="matstockdbheadecontainer">
                        <div className="matstockdbheadecontainer">
                            <h1 className="headertext" style={{ margin: '0px 0px 20px 0px', color: '#027585' }}>Circuland Marketplace</h1>
                            <p className="ptext"
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '10x'
                                }}
                            >
                                The Circuland Marketplace aims to connect supply and demand for re-used materials. The Marketplace is directly linked to the Circuland Materials Stock Database. When there is the intention to replace the materials, their Materials Passports will be automatically moved to the Marketplace. Additionally, new Materials Passports can be added in the Platform, for the Materials for the materials without Materials Passports.
                            </p>
                        </div>
                        <br />
                    </div>
                    <div className='circulandMarketplace-section2'>
                        <img
                            src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/14.webp"
                            alt="Material Stock DB Illustration"
                            className="image14"
                        />
                    </div>
                </div>
            </div>
            <div className="page gradient-background-page-NewPassMat" >
                {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
                <div className="page-underlay"
                >
                    <div className='circulandMarketplace-section2'>
                        <p
                            className="ptext"
                            style={{
                                width: '80%'
                            }}
                        >
                            The platform efficiently links supply and demand for re-used materials by matching the information that is recorded in the Materials Passports (of the materials available for re-use) and the filters that are set by the Marketplace users. The Marketplace is currently under construction and will be soon publicly available.
                        </p>
                        <br />
                        <img
                            src="https://circuland-website-images.s3.eu-west-2.amazonaws.com/14.1.webp"
                            alt="Material Stock DB Illustration"
                            style={{ width: '85%' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

// // SECTION - Section Continner will span 100% width and contain all 1 or multiple pages within this section
// <div className="section-container">
//     {/* SECTION - Page will span 100% width and min-height 100vh and represent 1 page within the UI - the background color and image will be changeable and applied here */}
//     <div className="page">
//         page
//         {/* SECTION - page-underlay will act as the width 1170px container to keep the contents inside. */}
//         <div className="page-underlay">
//             page-underlay
//         </div>
//     </div>
// </div>